import { Injectable } from "@angular/core";

@Injectable()
export class Configuration {}

export const AppConstants = {
  /**Production**/
  SERVER_CONFIG: {
    STAGING: "https://api.puppysbakery.com",
    PRODUCTION: "https://api.puppysbakery.com",
    LOCAL: "https://testapipuppys.technogenesis.in",
    REDIRECTIONSTAGING: `https://sharepuppysbakery.technogenesis.in`,
    REDIRECTIONPRODUCTION: `http://share.puppysbakery.com`,
    USE_URL: "LOCAL",
    IMG_URL: "",
  },
};

export const WordpressConfig = {
  baseURl: "https://blog.puppysbakery.com/wp-json/wp/v2/posts",
  username: "admin",
  password: "RyFQ QgCx nhE3 FXo8 J6IO PZKQ",
};

//RazorPayv
export const PaymentGateway = {
  paymentId: "rzp_test_6bWBwtjqDsuEzE", //"rzp_live_7Uq71mQp7pZlpL",
  testPaymentID: "rzp_test_6bWBwtjqDsuEzE",
  productionPaymentID: "rzp_live_bDokQmNqOJNwvj", //"rzp_live_H6mgBtLiFXMKsi",
  TGSSLive: "rzp_live_7Uq71mQp7pZlpL",
  testUPISuccess: "success@razorpay",
  testUPIFailure: "failure@razorpay",
};

export const StoreAddress = [
  {
    id: 1,
    name: "Puppy's Bakery (Kochadai)",
    address: "No.224, Theni Main Road, Madurai, Tamil Nadu 625 016",
    phone: "73977 33561/62, 0452 2381177",
    type: "storeAddress",
  },
  {
    id: 2,
    name: "Puppys Bakery (Anna Nagar)",
    address:
      " No 58, 80 Feet Rd, New LIG Colony, Anna Nagar, Sathamangalam, Madurai, Tamil Nadu 625020",
    phone: "73977 33563/64, 0452 2522270/80",
    type: "storeAddress",
  },
  {
    id: 4,
    name: "Puppy's Express (China Chokkikulam)",
    address:
      "No 32, Kamala 2nd Street, (Opp.Kids Talk), Chinna Chokkikulam, Madurai, Tamil Nadu 625002",
    phone: "0452 - 4959371",
    type: "storeAddress",
  },
  {
    id: 3,
    name: "Puppy's Bakery (Sivakasi)",
    address:
      "No.353,Thiruthangal Rd, Parasakthi Colony, Sivakasi, Tamil Nadu 626123",
    phone: "04562 222855",
    type: "storeAddress",
  },
];
